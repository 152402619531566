import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { searchParam } from "../util/util"
import { useLocation } from "react-router-dom"
import { readData, updateData } from "../modules/firebase";
import moment from "moment"
import { useState } from "react";
import CheckBox from "../Atoms/CheckBox";

const BoardPage = () => {
  const dispatch = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const { search } = useLocation();
  const [list, setList] = useState(Array(21).fill(0))
  const [listCount, setListCount] = useState(0)
  const [email, setEmail] = useState('0')
  const date = Number(moment(new Date()).format("YYMMDD"))
  const langUrl = document.location.ancestorOrigins[0].endsWith('.com')? "https://masterchoichallenge.com/login" : "https://masterchoichallenge.co.kr/login"

  const handlerChecked = (idx) => {
    var tempList = [...list]

    if (tempList[idx] === 0) {
      tempList[idx] = date
    } else {
      tempList[idx] = 0
    }
    dispatch(updateData({ email: email, list: tempList }))
    setList(tempList)

    var count = tempList.filter(element => 0 !== element).length;
    setListCount(count)
  }

  useEffect(() => {
    const paramEmail = searchParam(search, 'email')
    if (paramEmail !== undefined && paramEmail !== null && paramEmail !== '') {
      console.log(paramEmail.length)
      dispatch(readData({ email: paramEmail }))
      setEmail(paramEmail)
    }
  }, []);

  useEffect(() => {
    if (!fbState.loading) {
      console.log(fbState.list)
      setList(fbState.list)

      var count = fbState.list.filter(element => 0 !== element).length;
      setListCount(count)
    }
  }, [fbState.loading])

  useEffect(() => {
    console.log("list", list)
  }, [list])
  const days = [
    ["1day", "2day", "3day", "4day", "5day", "6day", "7day"],
    ["8day", "9day", "10day", "11day", "12day", "13day", "14day"],
    ["15day", "16day", "17day", "18day", "19day", "20day", "21day"]
  ];
  return (
    email.length >= 5 ?
      <>
        <div className='flex flex-col rounded-md m-[0.1rem] max-w-screen-2xl uppercase bg-black/5 font-bold px-4 h-[280px]'>
          <div className='px-[5%] '>
            <div className="flex flex-row">
              <div className="text-black/60 basis-1/2 text-left mx-2">Challenge Tracker</div>
              <div className="text-black/60 basis-1/2 text-right mx-2">{Math.round((listCount / 21) * 100)}%</div>
            </div>
            <div className="w-full bg-gray-300 rounded-full h-4 mb-6">
              <div className="bg-[#ab8742] h-4 rounded-full duration-75" style={{ width: `${(listCount / 21) * 100}%` }} />
            </div>
          </div>
          <div>
            <div className='grid grid-flow-col'>
              <CheckBox title={"1day"} onClick={() => handlerChecked(0)} checked={list[0] !== 0} />
              <CheckBox title={"2day"} onClick={() => handlerChecked(1)} checked={list[1] !== 0} />
              <CheckBox title={"3day"} onClick={() => handlerChecked(2)} checked={list[2] !== 0} />
              <CheckBox title={"4day"} onClick={() => handlerChecked(3)} checked={list[3] !== 0} />
              <CheckBox title={"5day"} onClick={() => handlerChecked(4)} checked={list[4] !== 0} />
              <CheckBox title={"6day"} onClick={() => handlerChecked(5)} checked={list[5] !== 0} />
              <CheckBox title={"7day"} onClick={() => handlerChecked(6)} checked={list[6] !== 0} />
            </div>
          </div>
          <div>
            <div className='grid grid-flow-col w-full'>
              <CheckBox title={"8day"} onClick={() => handlerChecked(7)} checked={list[7] !== 0} />
              <CheckBox title={"9day"} onClick={() => handlerChecked(8)} checked={list[8] !== 0} />
              <CheckBox title={"10day"} onClick={() => handlerChecked(9)} checked={list[9] !== 0} />
              <CheckBox title={"11day"} onClick={() => handlerChecked(10)} checked={list[10] !== 0} />
              <CheckBox title={"12day"} onClick={() => handlerChecked(11)} checked={list[11] !== 0} />
              <CheckBox title={"13day"} onClick={() => handlerChecked(12)} checked={list[12] !== 0} />
              <CheckBox title={"14day"} onClick={() => handlerChecked(13)} checked={list[13] !== 0} />
            </div>
          </div>
          <div>
            <div className='grid grid-flow-col'>
              <CheckBox title={"15day"} onClick={() => handlerChecked(14)} checked={list[14] !== 0} />
              <CheckBox title={"16day"} onClick={() => handlerChecked(15)} checked={list[15] !== 0} />
              <CheckBox title={"17day"} onClick={() => handlerChecked(16)} checked={list[16] !== 0} />
              <CheckBox title={"18day"} onClick={() => handlerChecked(17)} checked={list[17] !== 0} />
              <CheckBox title={"19day"} onClick={() => handlerChecked(18)} checked={list[18] !== 0} />
              <CheckBox title={"20day"} onClick={() => handlerChecked(19)} checked={list[19] !== 0} />
              <CheckBox title={"21day"} onClick={() => handlerChecked(20)} checked={list[20] !== 0} />
            </div>
          </div>
        </div>
      </> :
      <> 
        <a href={ langUrl } target="_top">
          <div className='flex flex-col rounded-md m-[0.1rem] max-w-screen-2xl uppercase bg-black/5 font-bold px-4 h-[280px] blur-[3px] bg-gray-50 brightness-[0.80] backdrop-blur-sm'>
            <div className='px-[5%] '>
              <div className="flex flex-row">
                <div className="text-black/60 basis-1/2 text-left mx-2">Challenge Tracker</div>
                <div className="text-black/60 basis-1/2 text-right mx-2">{0}%</div>
              </div>
              <div className="w-full bg-gray-300 rounded-full h-4 mb-6">
                <div className="bg-[#ab8742] h-4 rounded-full duration-75" style={{ width: `${0}%` }} />
              </div>
            </div>
            {days.map((week, index) => (
              <div key={index}>
                <div className="grid grid-flow-col w-full">
                  {week.map((day) => (
                    <CheckBox key={day} title={day} checked={false} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          
          <div class="absolute top-24 left-1/2 transform -translate-x-1/2 border-2 border-[#ab8742] bg-white rounded-lg p-2 shadow-sm">
            <div className="text-black/60 w-full text-base font-bold  text-center">
            Pregress Tracker
            <br/>
            <hr className="border-[1.5px] my-0.5"/>
            Login / Sign Up
            </div>
          </div>
        </a>
      </>
  );
}


export default React.memo(BoardPage);