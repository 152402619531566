import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { searchParam } from "../util/util"
import { useLocation } from "react-router-dom"
import { getChallengeData, getChallengeSetting, anonymousSignIn, updateChallengeData, updateData } from "../modules/firebase";
import moment from "moment"
import { useState } from "react";
import CheckBox from "../Atoms/CheckBox";
import CustomCheckBox from "../Atoms/CustomCheckBox";
import { set } from "animejs";

const CustomChallengePage = () => {
  const dispatch = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const { search } = useLocation();
  const [list, setList] = useState([0])
  const [listCount, setListCount] = useState(0)
  const [email, setEmail] = useState('0')
  const [challengeId, setChallengeId] = useState('0')
  const [target, setTarget] = useState('xingmove')
  const date = Number(moment(new Date()).format("YYMMDD"))

  const handlerChecked = (idx) => {
    var tempList = [...list]

    if (tempList[idx] === 0) {
      tempList[idx] = date
    } else {
      tempList[idx] = 0
    }
    dispatch(updateChallengeData({ email: email, id: challengeId, list: tempList }))
    setList(tempList)

    var count = tempList.filter(element => 0 !== element).length;
    setListCount(count)

    console.log(idx)
  }

  useEffect(() => {
    const paramEmail = searchParam(search, 'email')
    const paramId = searchParam(search, 'id')
    const paramTarget = searchParam(search, 'target')

    if ((paramId !== undefined && paramId !== null && paramId !== '') && (paramEmail !== undefined && paramEmail !== null && paramEmail !== '')) {
      
      dispatch(anonymousSignIn({ email: paramEmail, id:paramId }))

      // console.log(paramId.length)
      // dispatch(getChallengeSetting({ id: paramId }))
      setChallengeId(paramId)

      // console.log(paramEmail.length)
      // dispatch(getChallengeData({ email: paramEmail, id:paramId }))
      setEmail(paramEmail)
    }
    
    if(paramTarget !== undefined && paramTarget !==null && paramTarget !==''){
      setTarget(paramTarget);
    }
  }, []);

  useEffect(()=>{
    if (!fbState.loading) {
      setList( Array(Number(fbState.challengeSetting.period)).fill(0))
    }
  },[fbState.challengeSetting])

  useEffect(() => {
    if (!fbState.loading) {
      console.log(fbState.list)
      setList(fbState.list)

      var count = fbState.list.filter(element => 0 !== element).length;
      setListCount(count)
    }
  }, [fbState.list])

  useEffect(() => {
    console.log("list", list)
  }, [list])
  
  const days = [
    ["1day", "2day", "3day", "4day", "5day", "6day", "7day"],
    ["8day", "9day", "10day", "11day", "12day", "13day", "14day"],
    ["15day", "16day", "17day", "18day", "19day", "20day", "21day"]
  ];
  

  return (
    email.length >= 5 ?
      <>
        <div className='flex flex-col rounded-md m-[0.1rem] max-w-screen-2xl uppercase font-bold px-4' style={{backgroundColor : `${fbState.challengeSetting.bgColor}` }}>
          <div className='px-[5%] '>
            <div className="flex flex-row">
              <div className="text-black/60 basis-1/2 text-left mx-2">Challenge Tracker</div>
              <div className="text-black/60 basis-1/2 text-right mx-2">{Math.round((listCount / fbState.challengeSetting.period ) * 100)}%</div>
            </div>
            <div className="w-full bg-gray-300 rounded-full h-4 mb-6">
              <div className={`h-4 rounded-full duration-75`} style={{ width: `${(listCount / fbState.challengeSetting.period ) * 100}%`, backgroundColor : `${fbState.challengeSetting.mainColor}` }} />
            </div>
          </div>
          <div>
            <div className={`grid`}  style={{ gridTemplateColumns : ` repeat(${fbState.challengeSetting.col}, minmax(0, 1fr))` }} >
              {Array.from({ length: fbState.challengeSetting.period }).map((_, index) => (
                <CustomCheckBox 
                title={`${index+1}Day`} 
                checked={list[index] !==0 } 
                mainColor={fbState.challengeSetting.mainColor} 
                subColor={fbState.challengeSetting.subColor}
                onClick={() => handlerChecked(index)} 
                />
              ))}
            </div>
          </div>
        </div>
      </> :
      <> 
        <a href={ target ==='xingmove' ?  "https://xingmove.com/login" : "https://dokavi.com/login" } target="_top">
          <div className='flex flex-col rounded-md m-[0.1rem] max-w-screen-2xl uppercase bg-black/5 font-bold px-4 h-[280px] blur-[3px] bg-gray-50 brightness-[0.80] backdrop-blur-sm'>
            <div className='px-[5%] '>
              <div className="flex flex-row">
                <div className="text-black/60 basis-1/2 text-left mx-2">Challenge Tracker</div>
                <div className="text-black/60 basis-1/2 text-right mx-2">{0}%</div>
              </div>
              <div className="w-full bg-gray-300 rounded-full h-4 mb-6">
                <div className="bg-[#ab8742] h-4 rounded-full duration-75" style={{ width: `${0}%` }} />
              </div>
            </div>
            {days.map((week, index) => (
              <div key={index}>
                <div className="grid grid-flow-col w-full">
                  {week.map((day) => (
                    <CheckBox key={day} title={day} checked={false} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          
          <div class="absolute top-24 left-1/2 transform -translate-x-1/2 border-2 border-[#ab8742] bg-white rounded-lg p-2 shadow-sm">
            <div className="text-black/60 w-full text-base font-bold  text-center">
            Pregress Tracker
            <br/>
            <hr className="border-[1.5px] my-0.5"/>
            Login / Sign Up
            </div>
          </div>
        </a>
      </>
  );
}


export default React.memo(CustomChallengePage);