import { getFirestore, getDoc, setDoc, doc, collection, getDocs, updateDoc } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

class FirebaseRepository {
  constructor(app) {
    this.store = getFirestore(app);
    this.auth = getAuth(app);
  }

  async anonymousSignIn() {
    return signInAnonymously(this.auth)
      .then(({ user }) => {
        return user.uid
      })
      .catch(error => {
        console.error('Error during anonymous login', error);
      });
  }

  async updateData(email, list) {
    console.log(email, list)
    try {
      await setDoc(doc(this.store, "2022PIA", email), { list: list })
      console.log("Document written", list);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async readData(email) {
    const challengerRef = doc(this.store, "2022PIA", email);
    const challengerSnapshot = await getDoc(challengerRef);
    console.log('challengerSnapshot:')
    console.log(challengerSnapshot.id, challengerSnapshot.data().list)

    return challengerSnapshot.data().list
  }

  async updateChallengeData(email, id, list) {
    var document = {}
    document[id] = list

    console.log("updateChallengeData", email, id, list, document, id.toString())

    try {
      const docSnapshot = await getDoc(doc(this.store, "challenge", email));
      if (docSnapshot.exists()) {
        await updateDoc(doc(this.store, "challenge", email), document)
        console.log("Document update", document);
      } else {
        await setDoc(doc(this.store, "challenge", email), document)
        console.log("Document set", document);
      }

    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async getChallengeData(email, id) {
    const challengerRef = doc(this.store, "challenge", email);
    const challengerSnapshot = await getDoc(challengerRef);
    console.log('challengerSnapshot:')
    console.log(challengerSnapshot.id, challengerSnapshot.data()[id])

    return challengerSnapshot.data()[id]
  }

  async getChallengeSetting(id) {
    var ref = doc(collection(this.store, "challengeSetting"), id);

    let settingData = await getDoc(ref).then(async (item) => {
      let temp = item.data()
      temp.id = item.id
      console.log("settingData", temp)
      return temp;
    }).catch((error) => {
      console.error("Error writing document: ", error);
      return false
    })

    return settingData
  }
}

export default FirebaseRepository;
