import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FirebaseRepository from '../service/firebase_repository';
import { firebaseApp } from '../service/firebase_config';

const firebaseRepository = new FirebaseRepository(firebaseApp);

const initialState = {
  loading: true,
  challenge: [0],
  challengeSetting: {
    "title": 0,
    "mainColor": 0,
    "subColor": 0,
    "bgColor": 0,
    "period": 0,
    "col": 0,
    "id": 0
  }
};

/* DB */
export const updateData = createAsyncThunk(
  'firebase/update',
  async ({ email, list }) => {
    console.log('updateData', email)
    await firebaseRepository.updateData(email, list)
  }
)

export const readData = createAsyncThunk(
  'firebase/read',
  async ({ email }) => {
    console.log('readData', email)
    const response = await firebaseRepository.readData(email)
    return response
  }
)

export const updateChallengeData = createAsyncThunk(
  'firebase/Challenge/update',
  async ({ email, id, list }) => {
    console.log('updateData', email, id, list)
    await firebaseRepository.updateChallengeData(email, id, list)
  }
)

export const getChallengeData = createAsyncThunk(
  'firebase/read',
  async ({ email, id }) => {
    console.log('readData', email)
    const response = await firebaseRepository.getChallengeData(email, id)
    console.log("getChallengeData response", response)
    return response
  }
)

export const getChallengeSetting = createAsyncThunk(
  'firebase/ChallengeSetting/get',
  async ({ id }) => {
    const response = await firebaseRepository.getChallengeSetting(id)
    console.log("getChallengeSetting response", response)
    return response
  }
)

export const anonymousSignIn = createAsyncThunk(
  'firebase/anonymousSignIn/get',
  async ({ email, id }, { dispatch }) => {
    const response = await firebaseRepository.anonymousSignIn()
    console.log("anonymousSignIn response", response)
    await dispatch(getChallengeSetting({ id: id }))
    await dispatch(getChallengeData({ email: email, id: id }))
    return response
  }
)

export const firebaseRTK = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    setLogData: (state) => {
      console.log('setLog')
    },
  },
  extraReducers: {
    [updateData.fulfilled.type]: (state) => {
      console.log('updateData', state)
    },
    [readData.pending.type]: (state) => {
      state.loading = true;
    },
    [readData.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.list = action.payload
      state.loading = false;
    },
    [updateData.fulfilled.type]: (state) => {
      console.log('updateData', state)
    },
    [getChallengeData.pending.type]: (state) => {
      state.loading = true;
    },
    [getChallengeData.fulfilled.type]: (state, action) => {
      console.log('getChallengeData', action, action.payload)
      state.list = action.payload
      state.loading = false;
    },
    [getChallengeSetting.pending.type]: (state) => {
      state.loading = true;
    },
    [getChallengeSetting.fulfilled.type]: (state, action) => {
      console.log('challengeSetting', action, action.payload)
      state.challengeSetting = action.payload
      state.loading = false;
    },
    [anonymousSignIn.fulfilled.type]: (state) => {
      console.log('anonymousSignIn', state)
      state.loading = false;
    },
  }
})

export const { setLogData } = firebaseRTK.actions
