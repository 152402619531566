import style from "./App.module.css"
import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import BoardPage from './pages/BoardPage';
import ResultPage from "./pages/ResultPage";
import CustomChallengePage from "./pages/CustomChallengePage";

const App = () => {
  return (
    <div className={style.app} >
      <HashRouter >
        <Routes>
          <Route path="/" element={<BoardPage />} />
          <Route path="/challenge" element={<CustomChallengePage />} />
          <Route path="/mypage" element={<ResultPage />} />
        </Routes>
      </HashRouter >
    </div >
  );
}



export default React.memo(App);
