import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { searchParam } from "../util/util"
import { useLocation } from "react-router-dom"
import { readData, updateData } from "../modules/firebase";
import moment from "moment"
import { useState } from "react";
import CheckBox from "../Atoms/CheckBox";

const ResultPage = () => {
  const dispatch = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const { search } = useLocation();
  const [list, setList] = useState(Array(21).fill(0))
  const [listCount, setListCount] = useState(0)
  const [endDate, setEndDate] = useState(0)
  const [email, setEmail] = useState('0')
  const date = Number(moment(new Date()).format("YYMMDD"))

  const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-main_dark" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
  </svg>

  const handlerChecked = (idx) => {
    var tempList = [...list]

    if (tempList[idx] === 0) {
      tempList[idx] = date
    } else {
      tempList[idx] = 0
    }
    dispatch(updateData({ email: email, list: tempList }))
    setList(tempList)

    var count = tempList.filter(element => 0 !== element).length;
    setListCount(count)
  }

  const setHref = (href) => {
    window.parent.location.href = href
  }

  useEffect(() => {
    const paramEmail = searchParam(search, 'email')
    console.log(paramEmail.length)
    if (paramEmail !== undefined && paramEmail !== null && paramEmail !== '') {
      dispatch(readData({ email: paramEmail }))
      setEmail(paramEmail)
    }
  }, []);

  useEffect(() => {
    if (!fbState.loading) {
      console.log(fbState.list)
      setList(fbState.list)
      var tempList = [...fbState.list]

      var count = fbState.list.filter(element => 0 !== element).length;
      console.log("sort", tempList.sort())
      console.log("sort", tempList[tempList.length - 1])
      setListCount(count)

      var tempEndDate = tempList[tempList.length - 1].toString()

      setEndDate(tempEndDate.substr(0, 2) + "년 " + tempEndDate.substr(2, 2) + "월 " + tempEndDate.substr(4, 2) + "일")
    }
  }, [fbState.loading])

  useEffect(() => {
    console.log("list", list)
  }, [list])

  return (
    email.length >= 5 ?
      <>
        <div className="flex">
          <div className="text-black/60 w-full text-lg font-bold  text-center mx-2">내 챌린지</div>
        </div>
        <a className='flex flex-col rounded-md m-[0.1rem] max-w-screen-2xl uppercase bg-black/5 font-bold px-4' onClick={() => setHref('https://masterchoichallenge.com/2022PIA')}>
          <div className='px-[5%]'>
            <div className="flex flex-col">
              <div className="text-black/60 text-left mx-2">모닝 피아 스트레칭</div>
              <div className="border-b-2 rounded-md border-gray-300 w-full"></div>
              <div className="text-black/60 text-left mx-2">챌린지 기간 : 21일</div>
              <div className="text-black/60 text-left mx-2">운동타입 : 스트레칭 & 컨디셔닝</div>
            </div>
            <div className="flex flex-row">
              {
                list.includes(0) ?
                  <div className="text-black/60 basis-3/4 text-left mx-2">미완주</div>
                  :
                  <div className="text-black/60 basis-3/4 text-left mx-2">완주[{endDate}]</div>
              }

              <div className="text-black/60 basis-1/4 text-right mx-2">{Math.round((listCount / 21) * 100)}%</div>
            </div>
            <div className="w-full bg-gray-300 rounded-full h-4 mb-6">
              <div className="bg-[#ab8742] h-4 rounded-full duration-75" style={{ width: `${(listCount / 21) * 100}%` }} />
            </div>
          </div>
        </a>
      </> :
      <></>
  );
}


export default React.memo(ResultPage);
